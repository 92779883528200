import "./App.css";
const Events=()=>{
return(

    <div className="section">
        <div id="Events">OUR EVENTS</div>
        <section className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="img-fluid" id="rishikesh" />
              <div className="centered">Rishikesh festival</div>
              <div className="right">
                Experience Rishikesh Yoga Fest: 21 days of international
                music, workshops, cultural bliss, yoga, heartful
                melodies, and a delicious feast in the spiritual backdrop of the
                Himalayas.
              </div>
                {/* <a
                href="https://rishikeshkirtanfest.com/"
                id="learn"
                type="button"
                className="btn btn-outline-dark btn-md"
              >
                LEARN MORE
              </a> */}
            </div>
            <div className="col-md-6">
              <div className="img-fluid" id="karthik" />
              <div className="centered"id="heritage">Heritage Sites Educational Tour</div>
              <div className="right">
                Embark on a one-month journey in Vrindavan and Jaipur, weaving
                mantra meditations and vibrant Indian culture for a unique blend
                of spiritual renewal and cultural exploration.
              </div>
                {/* <a
                href="https://indradyumnaswamiparikrama.com/"
                id="learn"
                type="button"
                className="btn btn-outline-dark btn-md"
              >
                LEARN MORE
              </a> */}
            </div>
          </div>
        </section>
      </div>
)
}
export default Events;

import "./App.css";
import React, { useState} from "react";

const Form = () => {
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);

  const handleCollapsible1Click = () => {
    setActive1(!active1);
    setActive2(false); // Close the other content
  };

  const handleCollapsible2Click = () => {
    setActive2(!active2);
    setActive1(false); // Close the other content
  };
  return (
    <div className="section" id="donation">
      <div className="bg1" />
      <div className="bg2" />
      <div id="DonateUs">DONATE US</div>
      <div className="container">
        <form netlify
          name="contact"
          data-netlify="true" 
          method="POST"
          onSubmit="submit"
          encType="multipart/form-data"
          id="myForm"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="row">
            <div className="col">
              <h3 className="title">BILLING ADDRESS</h3>
              <div className="inputBox">
                <label htmlFor="fullName">
                  Full Name<span className="required">*</span>
                </label>
                <input
                  id="fullName"
                  name="full_name"
                  type="text"
                  placeholder="enter your name"
                  required=""
                />
              </div>
              <div className="inputBox">
                <label htmlFor="email">
                  Email ID<span className="required">*</span>
                </label>
                <input
                  id="email"
                  name="email_id"
                  type="email"
                  placeholder="name@xyz.com"
                  required=""
                />
              </div>
              <div className="inputBox">
                <span>Address</span>
                <input
                  type="text"
                  name="addrss"
                  placeholder="Door no. - street - locality"
                />
              </div>
              <div className="inputBox">
                <span>City</span>
                <input type="text" name="city" placeholder="Pune" />
              </div>
              <div className="flex">
                <div className="inputBox">
                  <span>State</span>
                  <input type="text" name="stte" placeholder="Maharashtra" />
                </div>
                <div className="inputBox">
                  <span>Zip Code</span>
                  <input type="text" name="zip_code" placeholder={123456} />
                </div>
              </div>
            </div>
            <div className="col">
              <h3 className="title">PAYMENT</h3>
              <button
                type="button"
                className={`collapsible ${active1 ? "active" : ""}`}
                onClick={handleCollapsible1Click}
                id="creditDebit"
              >
                <img src="credit-card.png" alt="Credit/Debit" /> Net Transfer
              </button>
              <div
                className={`content ${active1 ? "active" : ""}`}
                id="cardDetails"
              >
                {active1 &&
                <div>
                  <p>
                    Name: <span id="Trans">Sacred Seva Foundation</span>
                  </p>
                  <p>
                    Current A/C: <span id="Trans">50200048048272</span>
                  </p>
                  <p>
                    IFSC: <span id="Trans">HDFC0000542</span>
                  </p>
                </div>
                }
              </div>
              <button
                type="button"
                className={`collapsible ${active2 ? "active" : ""}`}
                onClick={handleCollapsible2Click}
                id="upi"
              >
                <img src="upi.png" alt="UPI" />
                UPI
              </button>
              <div
                className={`content ${active2 ? "active" : ""}`}
                id="upiDetail"
              >
                {active2 &&
                <div>
                  <p>
                    UPI ID: <span id="Trans">8097789978@hdfcbank</span>
                  </p>
                  <p>
                    Gpay/phonepay:<span id="Trans">8097789978</span>
                  </p>
                  <img id="upiId" src="upi_img.jpg" />
                </div>
                } 
              </div>
              <label id="receipt" htmlFor="myFile">
                Attach your receipt
              </label>
              <input
                type="file"
                id="myFile"
                name="filename"
                accept=".jpg, .png, .pdf"
                required=""
              />
              <input
                type="submit"
                defaultValue="Submit"
                name="submit"
                className="btn"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Form;

import "./App.css";
const AboutUs = () =>{
    return(
<div className="section" id="wp">
        <div id="AboutUs">ABOUT US</div>
        <div id="obj">
          <p style={{ textAlign: "center" }}>
            A Heartfelt Mission of Sacred Seva Foundation: Bringing Light to
            Lives
          </p>
          Discover SACRED SEVA FOUNDATION, a charitable sanctuary crafted for
          the common good. We're here to champion the pursuit of wisdom in
          philosophy, yoga, and the rich tapestry of ethical and
          spiritual insights. No matter who you are—regardless of race, caste,
          creed, gender, country, or social standing—our mission is to nurture
          the complete growth of every individual. Beyond personal development,
          we're dedicated to the greater good, aiming to contribute to public
          welfare and the advancement of knowledge for everyone. SACRED SEVA
          FOUNDATION invites you to embark on a journey where the exploration of
          profound ideas meets the practical evolution of mind, body, and
          spirit. Join hands with us as we strive to make knowledge a force for
          positive change, accessible to all.
        </div>
      </div>
    )
}
export default AboutUs;

import React, { useEffect, useState } from "react";
import "./App.css";
const Gallery =()=>{
    const [slideIndex, setSlideIndex] = useState(1);
    let timer;
  
    useEffect(() => {
      timer = setTimeout(() => plusSlides(1), 2000);
      return () => clearTimeout(timer); // Clear the timer on component unmount
    }, [slideIndex]);
  
    // Function to navigate to the next or previous slide
    const plusSlides = (n) => {
      const newIndex = slideIndex + n;
      showSlides(newIndex);
  
    };
  
    // Function to navigate to a specific slide
    const currentSlide = (n) => {
      showSlides(n);
    };
  
    // Function to display the slides
    const showSlides = (n) => {
      const slides = document.querySelectorAll('.slideshow-container .slides img');
      const dots = document.getElementsByClassName("dot");
      let newIndex = n;
  
      if (newIndex > slides.length) {
        newIndex = 1; // Reset index to the first slide
      }
      else if (newIndex < 1) {
        newIndex = 10; // Set index to the last slide
      }
  
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
  
      for (let i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace("active", "");
      }
  
      slides[newIndex-1].style.display = "block";
      dots[newIndex-1].className += " active";
  
      setSlideIndex(newIndex); 
    };
  
return(
    <div className="section">
      <div id="Gallery">GALLERY</div>
      <div className="slideshow-container">
        <div className="slides">
          <img src="gallery1.jpg" style={{ width: "100%", display: "block" }} />
          <img src="gallery2.jpg" style={{ width: "100%" }} />
          <img src="gallery3.jpg" style={{ width: "100%" }} />
          <img src="gallery4.jpg" style={{ width: "100%" }} />
          <img src="gallery5.jpg" style={{ width: "100%" }} />
          <img src="gallery6.jpg" style={{ width: "100%" }} />
          <img src="gallery7.jpg" style={{ width: "100%" }} />
          <img src="gallery8.jpg" style={{ width: "100%" }} />
          <img src="gallery9.jpg" style={{ width: "100%" }} />
          <img src="gallery10.jpg" style={{ width: "100%" }} />
        </div>

        <a className="prev" onClick={() => plusSlides(-1)}>
          ❮
        </a>
        <a className="next" onClick={() => plusSlides(1)}>
          ❯
        </a>

      </div>
      <br />
      <div id="motion" style={{ textAlign: "center" }}>
      {[...Array(10).keys()].map((index) => (
            <span
              key={index+1}
              className={index + 1 === slideIndex ? "dot active" : "dot"}
              onClick={() => currentSlide(index + 1)}
            />
          ))}
      </div>
    </div>
)
}
export default Gallery;
import "./App.css";
const Section1 =()=>{
    return(
        <div className="section">
        <div id="pg">
          <nav
            id="mainNav"
            className="navbar navbar-expand-md fixed-top"
            style={{ borderTopWidth: 5 }}
          >
            <a className="navbar-brand" href="ssf index.html" id="header">
              <img id="wallpaper" src="ssf.png" width={55} height={55} />
              <p>Sacred Seva Foundation</p>
            </a>
            <button
              type="button"
              className="navbar-toggler navbar-dark"
              data-toggle="collapse"
              data-target="#collapsed"
              aria-controls="collapsed"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="collapsed">
              <ul className="nav navbar-nav ml-auto mr-auto mt-2 mt-lg-0">
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a className="nav-link" href="#Home">
                    Home <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a className="nav-link" href="#AboutUs">
                    About Us
                  </a>
                </li>
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a className="nav-link" href="#WWD">
                    What We Do
                  </a>
                </li>
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a className="nav-link" href="#Events">
                    Events
                  </a>
                </li>
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a className="nav-link" href="#Gallery">
                    Gallery
                  </a>
                </li>
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a className="nav-link" href="#DonateUs">
                    Donate Us
                  </a>
                </li>
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a className="nav-link" href="#ContactUs">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div id="Home">OUR MOTTO</div>
          <div id="obj">
            SACRED SEVA FOUNDATION is an NGO created for charitable purposes
            with the objectives to promote and propagate studies and research in
            philosophy, yoga, ethical and spiritual culture and all
            branches of knowledge pertaining to the physical, mental, moral and
            spiritual development of people without any distinction as to the
            race, caste, creed, sex, country, religious denomination or social
            standing and advancement of any other object of public utility.
          </div>
        </div>
      </div>
    )
}
export default Section1;

import "./App.css";
const Contact = () => {
  return (
    <div className="section">
      <div id="contacting">
        <div id="ContactUs">CONTACT US</div>
        <div className="lhs">
          <span id="info">Registered Office :</span>
          <br />
          <span>
            A/14, B004 Aradhana CHS, Gokuldham,
            <br /> Goregaon East, Mumbai, Maharashtra - 400063.
          </span>
          <br />
          <span id="info">Income tax 80-G exemption number:</span>
          <br />
          <span>ABDCS3711KF20231 </span>
        </div>
        <div className="rhs">
          <span id="info">CIN number:</span>
          <br />
          <span>U85300MH2020NPL337147.</span>
          <br /> <span id="info">PAN:</span>
          <br />
          <span>ABDCS3711K</span>
          <br />
        </div>
        <div className="phn">
          <span id="info"> Contact :</span>
          <br />
          <span>+91-9840058505</span>
        </div>
      </div>
    </div>
  );
};
export default Contact;

import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect} from "react";
const WWD=()=>{
        useEffect(() => {
          AOS.init();
          AOS.refresh();
        }, []);
    return(
        <div className="section" id="pg3">
        <div id="WWD">WHAT WE DO</div>
        <div
          id="obj"
          style={{ paddingTop: 10 }}
          data-aos-offset={300}
          data-aos-easing="ease-in-sine"
          data-aos-duration={3000}
        >
          <div className="good-deed" data-aos="fade-right">
            Wisdom for All: Spread the joy of learning about life, philosophy,
            and yoga, all of which can be discovered through the rich culture and heritage of India.
          </div>
          <div className="good-deeds" data-aos="fade-left">
            Places of Learning: Create peaceful spaces where people can discover
            more about life,spirituality, and the beauty of different ideas.
          </div>
          <div className="good-deed" data-aos="fade-right">
            Helping Others: Give a helping hand to those who need it, especially
            in education and health, and teach everyone the good stuff that
            makes life better.
          </div>
          <div className="good-deeds" data-aos="fade-left">
            Safe Spaces: Make cozy places for people who want to explore yoga,
            spiritual practices, and understanding more about different beliefs.
          </div>
          <div className="good-deed" data-aos="fade-right">
            Amazing Stories: Share incredible stories and lessons through books
            about culture,philosophy, and the secrets of a happy life, in
            languages everyone can enjoy.
          </div>
          <div className="good-deeds" data-aos="fade-left">
            Caring for Animals: To safeguard the well-being of cattle,
            particularly protecting them from cruelty, neglect, and harm,
            establish and manage goshalas.
          </div>
          <div className="good-deed" data-aos="fade-right">
            Fun Gatherings: Bring people together for interesting talks,
            meetings, and events that celebrate our cultures and make us all
            think a bit.
          </div>
          <div className="good-deeds" data-aos="fade-left">
            Global Goodness: Share all the good things we do with the world,
            making sure everyone, no matter where they are, can feel the
            benefits.
          </div>
          <div className="good-deed" data-aos="fade-right">
            Sharing is Caring: Be smart with the money we get, share it with
            friends doing good things, and work with governments to make sure we
            can keep doing our good deeds.
          </div>
        </div>
      </div>
    )
}
export default WWD;

import "./App.css";
import AOS from "aos";
import Section1 from './section1';
import AboutUs from "./AboutUs";
import WWD from "./WWD";
import Events from "./Events";
import Gallery from "./Gallery";
import Form from "./Form";
import Contact from "./Contact";

function App() {

   return (
    <>
      <Section1/>
      <AboutUs/>
      <WWD/>
      <Events/>
      <Gallery/>
      <Form/>
      <Contact/>
    </>
  );
}
export default App;
